// extracted by mini-css-extract-plugin
export var tabContent = "_infoPos-module--tabContent--ct4R7";
export var tabLinks = "_infoPos-module--tabLinks--r7tu2";
export var tab = "_infoPos-module--tab---Lxub";
export var contentWrapper = "_infoPos-module--contentWrapper--5a620";
export var checkboxShow = "_infoPos-module--checkboxShow--TH0Wy";
export var checkbox = "_infoPos-module--checkbox--zUKwc";
export var productTableWeb = "_infoPos-module--productTableWeb--3E3Z4";
export var productTableMobile = "_infoPos-module--productTableMobile--IVOly";
export var imgXS = "_infoPos-module--imgXS--Sb-jP";
export var imgS = "_infoPos-module--imgS--8Vxgo";
export var imgM = "_infoPos-module--imgM--fEzdI";
export var imgL = "_infoPos-module--imgL--KydHq";
export var featureTable = "_infoPos-module--featureTable--gLY3X";
export var environmentTable = "_infoPos-module--environmentTable--g5JD-";
export var centerImg = "_infoPos-module--centerImg--hEPyh";
export var functionTable = "_infoPos-module--functionTable--jtA0I";
export var ttl = "_infoPos-module--ttl--eV1M8";
export var ttl2 = "_infoPos-module--ttl2--h59As";