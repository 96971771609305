import * as React from "react"
import { useEffect } from 'react'

import Layout from "src/components/layout"
import Seo from "src/components/seo"

import * as styles from "src/scss/_infoPos.module.scss"

import receiptPrinter from "src/images/info-POS/receipt-printer.jpg"
import display from "src/images/info-POS/display.jpg"
import cashDrawer from "src/images/info-POS/cash-drawer.jpg"
import barcodeReader from "src/images/info-POS/barcode-reader.jpg"
import infoPOSmedia from "src/images/info-POS/InfoPOSmedia.jpg"

import kokyakukannri from "src/images/info-POS/kokyakukannri.jpg"
import keyboard from "src/images/info-POS/keyboard.jpg"
import touchPanel from "src/images/info-POS/touch-panel.jpg"
import PLUpanel from "src/images/info-POS/PLU-panel.jpg"
import denshiJournal from "src/images/info-POS/denshi-journal.jpg"
import receipt1 from "src/images/info-POS/recept1.jpg"
import receipt2 from "src/images/info-POS/recept2.jpg"
import cxBanner from "src/images/info-POS/cx_banner.jpg"
import textData from "src/images/info-POS/text-data.jpg"
import csvlay from "src/images/info-POS/csvlay.jpg"
import stamp from "src/images/info-POS/stamp.jpg"
import autoCopy from "src/images/info-POS/AutoCopy.jpg"
import contactXML from "src/images/info-POS/contactXML.jpg"
import nonplu from "src/images/info-POS/nonplu.jpg"
import model from "src/images/info-POS/model.jpg"
import width from "src/images/info-POS/width.jpg"

import ARCXsmall from "src/images/info-POS/ARCXsmall.jpg"
import IMG00002258 from "src/images/info-POS/IMG0002258-1.jpeg"
import IMG00002259 from "src/images/info-POS/IMG0002259-1.jpeg"
import IMG00002256 from "src/images/info-POS/IMG0002256-1.jpeg"

import customters from "src/images/info-POS/customters.jpg"
import newItems from "src/images/info-POS/new-items.jpg"
import oyako from "src/images/info-POS/oyako.jpg"

export default function InfoPosPage () {
  useEffect(() => {
    let defaultOpen = document.getElementById("defaultOpen")

    if(defaultOpen != null){
      defaultOpen.click();
    }

    let defaultOpen2 = document.getElementById("feature1")

    if(defaultOpen2 != null){
      defaultOpen2.checked = true
    }

    let defaultOpen3 = document.getElementById("qa1")

    if(defaultOpen3 != null){
      defaultOpen3.checked = true
    }
  });

  let click = (id, e) => {
    let i, tabContent, tabLinks;

    tabContent = document.getElementsByClassName("_infoPos-module--tabContent--ct4R7");
    for (i = 0; i < tabContent.length; i++) {
      tabContent[i].style.display = "none";
    }

    tabLinks = document.getElementsByClassName("_infoPos-module--tabLinks--r7tu2");
    for (i = 0; i < tabLinks.length; i++) {
      tabLinks[i].className = tabLinks[i].className.replace(" active", "");
      tabLinks[i].style = tabLinks[i].style.backgroundColor = "#fff" ;
      e.currentTarget.style.borderTop = "2px solid #2C577E" ;
      e.currentTarget.style.borderLeft = "2px solid #2C577E" ;
      e.currentTarget.style.borderRight = "2px solid #2C577E" ;
    }
    document.getElementById(id).style.display = "block";
    e.currentTarget.style.backgroundColor = "#EAE9E9" ;
    e.currentTarget.style.borderTop = "2px solid #EAE9E9" ;
    e.currentTarget.style.borderLeft = "2px solid #EAE9E9" ;
    e.currentTarget.style.borderRight = "2px solid #EAE9E9" ;
    e.currentTarget.style.color = "#414141" ;
    e.currentTarget.style.boxShadow = "none" ;
  };

  return (
    <Layout>
      <Seo title="インフォ・ザ・伝票" />
      <div className="pageWrapper">
        <h1 className="productName">Info-POS</h1>
        <p>
          Info-POSは、パソコンをPOSレジとして使うためのPC－POSソフトです。<br />
          低価格でお手軽にPOSレジを導入できます。
        </p>

        <hr className="hrGrey" />

        <h2 className="h2Blue">製品情報</h2>
        <p className="bold">1. Info-POS Ver.2ソフトインストールメディア(USBフラッシュメモリ)</p>
        <p>内容</p>
        <ul>
          <li>Info-POS Ver.2インストールメディア、ライセンスコード、インストールマニュアル</li>
          <li>メール、FAX、電話での無償サポート3ヶ月間</li>
        </ul>
        <table className={styles.productTableWeb}>
          <thead>
            <tr>
              <th colSpan="5">Info-POS接続機器の構成例</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <img src={ receiptPrinter } alt="サーマルレシートプリンタ"/>
              </td>
              <td>
                <img src={ display } alt="カスタマディスプレイ"/>
              </td>
              <td>
                <img src={ cashDrawer } alt="キャッシュドロワ"/>
              </td>
              <td>
                <img src={ barcodeReader } alt="バーコードリーダ"/>
              </td>
              <td>
                <img src={ infoPOSmedia } alt="Info-POSソフト"/>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>サーマルレシートプリンタ<br />TM-T90</td>
              <td>カスタマディスプレイ<br />DM-D110ST</td>
              <td>キャッシュドロワ<br />DMA-48EDIII</td>
              <td>バーコードリーダ<br />FFTA10AUSB</td>
              <td>Info-POSソフト Ver.2<br />インストールメディア (USBフラッシュメモリ)</td>
            </tr>
          </tfoot>
        </table>
        <table className={styles.productTableMobile}>
          <thead>
            <tr>
              <th colSpan="2">Info-POS接続機器の構成例</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><img src={ receiptPrinter } alt="サーマルレシートプリンタ"/></td>
              <td>サーマルレシートプリンタ<br />TM-T90</td>
            </tr>
            <tr>
              <td><img src={ display } alt="カスタマディスプレイ"/></td>
              <td>カスタマディスプレイ<br />DM-D110ST</td>
            </tr>
            <tr>
              <td><img src={ cashDrawer } alt="キャッシュドロワ"/></td>
              <td>キャッシュドロワ<br />DMA-48EDIII</td>
            </tr>
            <tr>
              <td><img src={ barcodeReader } alt="バーコードリーダ"/></td>
              <td>バーコードリーダ<br />FFTA10AUSB</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td><img src={ infoPOSmedia } alt="Info-POSソフト"/></td>
              <td>Info-POSソフト Ver.2<br />インストールメディア (USBフラッシュメモリ)</td>
            </tr>
          </tfoot>
        </table>
        <p className="italic">※ レシート幅は58mmまたは80mmとなります。</p>
        <p className="italic">※ Info-POSは2色印字には対応しておりません。</p>
        <p className="italic">※ Info-POSソフトに、印刷された操作マニュアルは付属しません。操作マニュアルはインストールメディア内のPDF形式ファイルをご利用下さい。</p>

        <p className="bold">2. 年間サポート <span className="italic">※任意</span></p>
        <p>内容</p>
        <ul>
          <li>Info-POSに関するメール、FAX、電話でのサポート</li>
        </ul>

        <h2 className="h2Blue">Info-POSに関するお問い合せ</h2>
        <p className="productMsg">
          お気軽に以下の窓口までお問い合わせください。
        </p>

        <div className="contactWrapper">
          <p className="companyName">リンクチャネル(株)長岡CSセンター</p>
          <p className="telNum">
            <a href="tel:0258-25-6320">お問い合せ専用電話：
              <span>0258-25-6320</span>
            </a>
          </p>
          <p>受付時間：弊社休業日、祭日を除く月曜-金曜の10:00 ~ 17:00</p>
          <p className="telNum">
            <a href="mailto:pos@renk.co.jp">
              E-Mail：pos@renk.co.jp
            </a>
          </p>
          <p>Fax：0258-25-6309</p>
        </div>

        <div>
          <div className={styles.tab}>
            <button className={styles.tabLinks} onClick={(e) => click('feature', e)} id="defaultOpen">主な特徴</button>
            <button className={styles.tabLinks} onClick={(e) => click('environment', e)}>動作環境</button>
            <button className={styles.tabLinks} onClick={(e) => click('function', e)} >機能一覧</button>
            <button className={styles.tabLinks} onClick={(e) => click('qa', e)} >商品Q&A</button>
          </div>

          <div className={styles.contentWrapper}>
            <div id="feature" className={styles.tabContent}>
              <input type="checkbox" id="feature1" className={styles.checkbox} />
              <label htmlFor="feature1">豊富な決済機能</label>
              <div className={styles.checkboxShow}>
                <p>現金、売掛、クレジットカード、商品券を一取引に混在して決済可能です。商品券の釣銭設定(可、不可)やクーポンにも対応しています。</p>
              </div>

              <input type="checkbox" id="feature2" className={styles.checkbox} />
              <label htmlFor="feature2">顧客管理</label>
              <div className={styles.checkboxShow}>
                <p>売上時に顧客コードを打ち込むことで、顧客の購買データを記録できます。バーコード付顧客カードを利用すればバーコードをスキャンするだけでOKです。また、購買履歴をレジ画面から参照することもできます。</p>
                <p className="italic">※ バーコード付顧客カードの発行機能はありません。</p>
                <img src={ kokyakukannri } alt="顧客別購買履歴照会画面" className={styles.imgL} />
              </div>

              <input type="checkbox" id="feature3" className={styles.checkbox} />
              <label htmlFor="feature3">タッチパネル、キーボード両対応</label>
              <div className={styles.checkboxShow}>
                <p>通常のキーボードでの操作に適したキーボード操作用画面と、タッチパネルでの操作に対応したタッチパネル操作用の二つの画面を用意しました。</p>
                <p>タッチパネルを使うことで、より簡便な操作でご利用いただけます。(タッチパネルはマウスエミュレーションのものに対応)また、タッチパネル用画面では、商品をタッチパネルで選択できるPLUパネルも使用できます。</p>
                <p className="bold">キーボード操作用画面</p>
                <img src={ keyboard } alt="キーボード操作用画面" className={styles.imgL} />
                <p className="bold">タッチパネル操作用画面</p>
                <img src={ touchPanel } alt="タッチパネル操作用画面" className={styles.imgL} />
                <p className="bold">PLUパネル</p>
                <img src={ PLUpanel } alt="PLUパネル" className={styles.imgL} />
              </div>

              <input type="checkbox" id="feature4" className={styles.checkbox} />
              <label htmlFor="feature4">電子ジャーナルに対応</label>
              <div className={styles.checkboxShow}>
                <p>Info-POSは電子ジャーナルに対応しています。ジャーナルデータは任意の3カ所への同時記録ができます。例えば、複数のディスクに分散して記録するように構成すれば大事なジャーナルデータを不測の事態から守ることができます。保管されているジャーナルは任意の日付でいつでも見ることができます。</p>
                <p className="italic">※ 電子ジャーナルのご利用にはお客様が所轄の税務署へ申請する必要があります。電子ジャーナルをご利用にならない場合は、ジャーナル印刷機能でジャーナルを印刷して保管して下さい。</p>
                <img src={ denshiJournal } alt="取引ジャーナル照会画面" className={styles.imgL} />
              </div>

              <input type="checkbox" id="feature5" className={styles.checkbox} />
              <label htmlFor="feature5">領収証発行が簡単なバーコード付レシート</label>
              <div className={styles.checkboxShow}>
                <p>Info-POSのレシートはバーコード付です。このバーコードをスキャンするだけで簡単に領収証の発行ができます。</p>
                <img src={ receipt1 } alt="バーコード付レシート" className={styles.imgS} />
                <img src={ receipt2 } alt="領収証" className={styles.imgS} />
              </div>

              <input type="checkbox" id="feature6" className={styles.checkbox} />
              <label htmlFor="feature6">充実した他ソフトとの連携</label>
              <div className={styles.checkboxShow}>
                <p>すべてのマスターと売上データ(売上ジャーナル)はテキストファイルでの入出力が可能です。(売上データは出力のみ)
                  他のソフトで作成したマスターをInfo-POSに取り込んだり、Info-POSの売上データを他のソフトで活用することができます。
                </p>
                <p>入出力するCSVファイルの項目や項目順を相手ソフトのレイアウトに合わせて、設定することができますので、データ交換の自由度が飛躍的に高くなりました。また、顧客マスタの入出力は、市販の宛名書きソフトとの連携が簡単になるContactXML形式に対応しました。</p>
                <img src={ cxBanner } alt="ContactXML形式対応バナー" className={styles.imgXS} /><br />
                <img src={ textData } alt="テキストデータ入出力画面" className={styles.imgL} /><br />
                <img src={ csvlay } alt="CSVレイアウト編集画面" className={styles.imgL} />
              </div>

              <input type="checkbox" id="feature7" className={styles.checkbox} />
              <label htmlFor="feature7">オリジナルスタンプ</label>
              <div className={styles.checkboxShow}>
                <p>レシートに印刷するスタンプ部はポピュラーなWindowsビットマップ形式です。オリジナリティあふれたスタンプロゴを簡単に作成することができます。</p>
                <p className="italic">※ Windowsビットマップを作成する機能は含まれておりません。Windows附属ペイントや市販のイメージ作成ソフトをご利用下さい。</p>
                <img src={ stamp } alt="オリジナルスタンプ" className={styles.imgS} />
              </div>

              <input type="checkbox" id="feature8" className={styles.checkbox} />
              <label htmlFor="feature8">レイアウトを定義、保存できるCSV入出力機能</label>
              <div className={styles.checkboxShow}>
                <p>Info-POS Ver.2.0ではCSV入出力の、項目、並び順などを設定し、この情報を保存、読込できます。販売管理ソフト等、他のソフトとのデータ交換がより容易にできるようになりました。</p>
                <img src={ textData } alt="テキストデータ入出力画面" className={styles.imgL} />
                <img src={ csvlay } alt="CSVレイアウト編集画面" className={styles.imgL} />
              </div>

              <input type="checkbox" id="feature9" className={styles.checkbox} />
              <label htmlFor="feature9">売上データCSVファイル自動出力</label>
              <div className={styles.checkboxShow}>
                <p>売上データのCSVファイル自動出力を設定しておくことで、精算処理時に売上データCSVファイルを指定された場所へ自動的に出力できるようになりました。毎日の売上をCSVファイルに出力して別パソコンで集計しているような場合、操作の負担が大幅に軽減されます。</p>
                <img src={ autoCopy } alt="売上データCSVファイル自動出力構成図" className={styles.imgL} />
              </div>

              <input type="checkbox" id="feature10" className={styles.checkbox} />
              <label htmlFor="feature10">ContactXML対応</label>
              <div className={styles.checkboxShow}>
                <p>顧客マスタの入出力でContactXML形式に対応しました。筆まめ、筆王などContactXMLに対応している市販の宛名書きソフト、年賀状ソフトとのデータ交換が容易になります。</p>
                <img src={ contactXML } alt="ContactXML対応構成図" className={styles.imgL} />
              </div>

              <input type="checkbox" id="feature11" className={styles.checkbox} />
              <label htmlFor="feature11">Non-PLU機能</label>
              <div className={styles.checkboxShow}>
                <p>バーコード中に価格情報を埋め込むNon-PLUに対応しました。生鮮食品などバーコードに価格を埋め込んだ商品に対応できるようになりました。</p>
                <img src={ nonplu } alt="Non-PLU設定画面" className={styles.imgL} />
              </div>

              <input type="checkbox" id="feature12" className={styles.checkbox} />
              <label htmlFor="feature12">対応プリンタの拡大</label>
              <div className={styles.checkboxShow}>
                <p>従来、TM-T90とTM-T88IIR(58mmモデル)のみに対応していましたが、これらに加えてTM-T88IIR(80mmモデル)、TM-T88III(58mm、80mmモデル)、TM-J2100/2000、TM-T88Ⅳ(58mm、80mmモデル)、TM-T70(58mm、80mmモデル)に対応しました。また、58mmレシート、80mmレシート何れでもご利用いただけるようになりました。</p>
                <img src={ model } alt="プリンター選択画面" className={styles.imgL} />
                <img src={ width } alt="レシート幅選択画面" className={styles.imgL} />

                <table className={styles.featureTable}>
                  <thead>
                    <tr>
                      <th>レシートプリンタ</th>
                      <th>Ver.1.0</th>
                      <th>Ver.2.0</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>TM-T90(シリアル)*</td>
                      <td>○</td>
                      <td>○</td>
                    </tr>
                    <tr>
                      <td> TM-T88IIR(58mm)</td>
                      <td>○</td>
                      <td>○</td>
                    </tr>
                    <tr>
                      <td>TM-T88IIR(80mm)</td>
                      <td>×</td>
                      <td>○</td>
                    </tr>
                    <tr>
                      <td>TM-T88III(シリアル、58mm)</td>
                      <td>×</td>
                      <td>○</td>
                    </tr>
                    <tr>
                      <td>TM-T88III(シリアル、80mm)</td>
                      <td>×</td>
                      <td>○</td>
                    </tr>
                    <tr>
                      <td>TM-J2100/2000(シリアル)*</td>
                      <td>-</td>
                      <td>○</td>
                    </tr>
                    <tr>
                      <td>TM-T88Ⅳ(シリアル、58mm)</td>
                      <td>-</td>
                      <td>○</td>
                    </tr>
                    <tr>
                      <td>TM-T88Ⅳ(シリアル、80mm)</td>
                      <td>-</td>
                      <td>○</td>
                    </tr>
                    <tr>
                      <td>TM-T70(シリアル、58mm)</td>
                      <td>-</td>
                      <td>○</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>TM-T70(シリアル、80mm)</td>
                      <td>-</td>
                      <td>○</td>
                    </tr>
                  </tfoot>
                </table>
                <p className="italic">※ 2色印字には対応しておりません。</p>
              </div>
            </div>

            <div id="environment" className={styles.tabContent}>
              <table className={styles.environmentTable}>
                <thead>
                  <tr>
                  <th colSpan="2">対応OS</th>
                    <td>Windows2000Professional、WindowsXP Home Edition(SP1以上)、WindowsXP Professional(SP1以上)</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2">対応パソコン</th>
                    <td>上記OSが動作し、シリアルポート1ポート*1、PS2キーボードインタフェース(USBスキャナを使う場合はUSBインタフェース)を持つもの</td>
                  </tr>
                  <tr>
                    <th colSpan="2">対応レシートプリンタ</th>
                    <td>エプソン TM-T90(シリアルインタフェース)*2、TM-T88III(シリアルインタフェース、58mmレシート、80mmレシート)、TM-J2100/2000(シリアルインタフェース)*2、TM-T88IVシリアルインタフェース、58mmレシート、80mmレシート)、TM-T70(シリアルインタフェース、58mmレシート、80mmレシート)</td>
                  </tr>
                  <tr>
                    <th colSpan="2">対応カスタマディスプレイ</th>
                    <td>エプソン DM-D110ST、DM-D210ST、DM-D110MJ、DM-D210MJ</td>
                  </tr>
                  <tr>
                    <th colSpan="2">対応キャッシュドロワ</th>
                    <td>エプソン DLA-58EDIII、DMA-48EDIII、DSA-35EDIII</td>
                  </tr>
                  <tr>
                    <th colSpan="2">対応バーコードスキャナ</th>
                    <td>シンボルテクノロジー LS2208(USBインタフェース、キーボードインタフェース)<br />
                      日栄インテック FFTA10Aシリーズ(USBインタフェース、キーボードインタフェース)<br />
                      日栄インテック FFTA10ABT(ワイヤレススキャナ、USBインタフェース、キーボードインタフェース)
                    </td>
                  </tr>
                  <tr>
                    <th rowSpan="3">対応POSレジ</th>
                    <td className={styles.centerImg}><img src={ ARCXsmall } alt="エプソン SASPORT URBANII" /></td>
                    <td><span>[本体]</span> エプソン SASPORT URBANII<br />
                      SASPORT URBAN(UR-2K21LBまたはW) SVGA、Win2K<br />
                      <span>[レシートプリンタ]</span> エプソン TM-T90(シリアルインタフェース)、TM-T88III(シリアルインタフェース、58mmレシート、80mmレシート)、TM-J2100/2000(シリアルインタフェース)<br />
                      <span>[カスタマディスプレイ]</span> エプソン DM-D110BまたはW<br />
                      <span>[バーコードスキャナ]</span> エプソン DM-S162(USBインタフェース)<br />
                      <span>[キャッシュドロワ]</span> エプソン DM-Z400、DM-Z100
                    </td>
                  </tr>
                  <tr>
                    <td className={styles.centerImg}><img src={ IMG00002258 } alt="エプソン SASPORT URBANII" /></td>
                    <td><span>[本体]</span> エプソン SASPORT URBANII<br />
                      SASPORT URBAN(UR-2K21LBまたはW) SVGA、Win2K<br />
                      <span>[レシートプリンタ]</span> エプソン TM-T90(シリアルインタフェース)、TM-T88III(シリアルインタフェース、58mmレシート、80mmレシート)、TM-J2100/2000(シリアルインタフェース)<br />
                      <span>[カスタマディスプレイ]</span> エプソン DM-D110BまたはW<br />
                      <span>[バーコードスキャナ]</span> エプソン DM-S162(USBインタフェース)<br />
                      <span>[キャッシュドロワ]</span> エプソン DM-Z400、DM-Z100
                    </td>
                  </tr>
                  <tr>
                    <td className={styles.centerImg}><img src={ IMG00002259 } alt="エプソン SASPORT MR SVGA、Win2K" /></td>
                    <td><span>[本体]</span> エプソン SASPORT MR SVGA、Win2K<br />
                      <span>[レシートプリンタ]</span> エプソン TM-T90(シリアルインタフェース)、TM-T88III(シリアルインタフェース、58mmレシート、80mmレシート)、TM-J2100/2000(シリアルインタフェース)<br />
                      <span>[カスタマディスプレイ]</span> エプソン DM-D110ST、DM-D210ST<br />
                      <span>[バーコードスキャナ]</span> エプソン DM-S162(USBインタフェース)<br />
                      <span>[キャッシュドロワ]</span> エプソン DM-Z400、DM-Z100
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th></th>
                    <td className={styles.centerImg}><img src={ IMG00002256 } alt="エプソン SASPORT CubieIII" /></td>
                    <td><span>[本体]</span> エプソン SASPORT CubieIII(全モデル) XGA、SVGA、Win2K、58mmレシート、80mmレシート<br />
                      <span>[カスタマディスプレイ]</span> エプソン DM-D110CB<br />
                      <span>[バーコードスキャナ]</span> エプソン DM-S161(キーボードインタフェース)<br />
                      <span>[キャッシュドロワ]</span> エプソン DM-Z400、DM-Z460<br />
                      <span>[POSキーボード]</span> エプソン DM-KR028
                    </td>
                  </tr>
                </tfoot>
              </table>
              <p className="italic">※1 USB-シリアル変換ケーブルでも可</p>
              <p className="italic">※2 TM-T90、TM-J2100の2色印字には対応しておりません。</p>
            </div>

            <div id="function" className={styles.tabContent}>
              <table className={styles.functionTable}>
                <thead>
                  <tr className={styles.ttl}>
                    <th>機能</th>
                    <th>機能概要</th>
                    <th>備考</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th rowSpan="4" className={styles.ttl2}>開設</th>
                    <td>担当者登録</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>営業日付登録</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>時刻確認・調整</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>釣銭準備金登録</td>
                    <td></td>
                  </tr>
                  <tr>
                  <th colSpan="3" className={styles.ttl}>レジ</th>
                </tr>
                <tr>
                  <th rowSpan="14" className={styles.ttl2}>売上</th>
                  <td>単品スキャニング</td>
                  <td></td>
                </tr>
                <tr>
                  <td>単品コード入力</td>
                  <td></td>
                </tr>
                <tr>
                  <td>部門コード入力</td>
                  <td></td>
                </tr>
                <tr>
                  <td>部門パネル</td>
                  <td></td>
                </tr>
                <tr>
                  <td>客層パネル</td>
                  <td></td>
                </tr>
                <tr>
                  <td>数量訂正</td>
                  <td>リピート付</td>
                </tr>
                <tr>
                  <td>単価訂正</td>
                  <td>処理中取引の売価変更</td>
                </tr>
                <tr>
                  <td>売価変更</td>
                  <td>マスタの売価変更</td>
                </tr>
                <tr>
                  <td>売価０円確認</td>
                  <td>ワーニング表示</td>
                </tr>
                <tr>
                  <td>返品</td>
                  <td></td>
                </tr>
                <tr>
                  <td>レジマイナス</td>
                  <td>当日取引のみ</td>
                </tr>
                <tr>
                  <td>顧客NO入力</td>
                  <td></td>
                </tr>
                <tr>
                  <td>客層NO入力</td>
                  <td></td>
                </tr>
                <tr>
                  <td>担当者NO入力</td>
                  <td></td>
                </tr>
                <tr>
                  <th rowSpan="7" className={styles.ttl2}>値引</th>
                  <td>単品値引</td>
                  <td></td>
                </tr>
                <tr>
                  <td>単品割引</td>
                  <td></td>
                </tr>
                <tr>
                  <td>部門値引</td>
                  <td></td>
                </tr>
                <tr>
                  <td>部門割引</td>
                  <td></td>
                </tr>
                <tr>
                  <td>小計後値引</td>
                  <td rowSpan="2">明細計に対して行い税算出は再計算</td>
                </tr>
                <tr>
                  <td>小計後割引</td>
                </tr>
                <tr>
                  <td>クーポン</td>
                  <td></td>
                </tr>
                <tr>
                  <th rowSpan="5" className={styles.ttl2}>訂正</th>
                  <td>直前取消</td>
                  <td></td>
                </tr>
                <tr>
                  <td>直前訂正</td>
                  <td></td>
                </tr>
                <tr>
                  <td>指定行取消</td>
                  <td></td>
                </tr>
                <tr>
                  <td>指定行訂正</td>
                  <td></td>
                </tr>
                <tr>
                  <td>取引中止</td>
                  <td></td>
                </tr>
                <tr>
                  <th rowSpan="4" className={styles.ttl2}>決済</th>
                  <td>クレジットカード決済</td>
                  <td>カード番号手入力</td>
                </tr>
                <tr>
                  <td>商品券決済</td>
                  <td>１取引３種類まで</td>
                </tr>
                <tr>
                  <td>売掛決済</td>
                  <td></td>
                </tr>
                <tr>
                  <td>万券</td>
                  <td></td>
                </tr>
                <tr>
                  <th rowSpan="2" className={styles.ttl2}>特売処理</th>
                  <td>期間サービス</td>
                  <td></td>
                </tr>
                <tr>
                  <td>タイムサービス</td>
                  <td></td>
                </tr>
                <tr>
                  <th rowSpan="2" className={styles.ttl2}>レシート</th>
                  <td>レシート印刷</td>
                  <td rowSpan="2">レシートNoバーコード印字<br />
                    印紙エリア自動スキップ</td>
                </tr>
                <tr>
                  <td>後レシート指示印刷</td>
                </tr>
                <tr>
                  <th rowSpan="2" className={styles.ttl2}>領収書</th>
                  <td>直前取引発行</td>
                  <td></td>
                </tr>
                <tr>
                  <td>レシートバーコード読取発行</td>
                  <td></td>
                </tr>
                <tr>
                  <th rowSpan="5" className={styles.ttl2}>売上関連</th>
                  <td>印紙張付指示</td>
                  <td>画面にて通知</td>
                </tr>
                <tr>
                  <td>新商品簡易登録</td>
                  <td>新商品リストレシート出力</td>
                </tr>
                <tr>
                  <td>新顧客簡易登録</td>
                  <td>新顧客リストレシート出力</td>
                </tr>
                <tr>
                  <td>顧客別購買履歴照会</td>
                  <td>売上商品履歴</td>
                </tr>
                <tr>
                  <td>保留</td>
                  <td>１取引のみ</td>
                </tr>
                <tr>
                  <th rowSpan="5" className={styles.ttl2}>売上外</th>
                  <td>入金登録</td>
                  <td>科目＝現金</td>
                </tr>
                <tr>
                  <td>出金登録</td>
                  <td>科目＝現金</td>
                </tr>
                <tr>
                  <td>掛入金登録</td>
                  <td>科目＝現金</td>
                </tr>
                <tr>
                  <td>両替</td>
                  <td></td>
                </tr>
                <tr>
                  <td>休止</td>
                  <td></td>
                </tr>
                <tr>
                  <th className={styles.ttl2}>取引確認(画面&レシート)</th>
                  <td>ジャーナル照会</td>
                  <td>ジャーナルの選択、レシートプリンタへの印刷</td>
                </tr>
                <tr>
                  <th className={styles.ttl2}>トレーニング</th>
                  <td>レジ機能同様</td>
                  <td></td>
                </tr>
                <tr>
                  <th colSpan="3" className={styles.ttl}>点検</th>
                </tr>
                <tr>
                  <th rowSpan="22" className={styles.ttl2}>レシート印刷</th>
                  <td rowSpan="11">取引別集計</td>
                  <td>売上集計</td>
                </tr>
                <tr>
                  <td>値引集計</td>
                </tr>
                <tr>
                  <td>小計値引集計</td>
                </tr>
                <tr>
                  <td>返品集計</td>
                </tr>
                <tr>
                  <td>レジマイナス集計</td>
                </tr>
                <tr>
                  <td>税金集計</td>
                </tr>
                <tr>
                  <td>売掛集計</td>
                </tr>
                <tr>
                  <td>領収書集計</td>
                </tr>
                <tr>
                  <td>印紙集計</td>
                </tr>
                <tr>
                  <td>クーポン集計</td>
                </tr>
                <tr>
                  <td>レシート２枚以上</td>
                </tr>
                <tr>
                  <td rowSpan="3">売上外集計</td>
                  <td>入金集計</td>
                </tr>
                <tr>
                  <td>出金集計</td>
                </tr>
                <tr>
                  <td>掛入金集計</td>
                </tr>
                <tr>
                  <td rowSpan="2">在高集計</td>
                  <td>釣銭準備金</td>
                </tr>
                <tr>
                  <td>現金集計</td>
                </tr>
                <tr>
                  <td>商品券集計</td>
                  <td></td>
                </tr>
                <tr>
                  <td>クレジットカード明細</td>
                  <td></td>
                </tr>
                <tr>
                  <td>部門別売上実績</td>
                  <td></td>
                </tr>
                <tr>
                  <td>オペレータ別売上実績</td>
                  <td></td>
                </tr>
                <tr>
                  <td>時間帯別売上実績</td>
                  <td></td>
                </tr>
                <tr>
                  <td>客層別売上実績</td>
                  <td></td>
                </tr>
                <tr>
                  <th className={styles.ttl2}>点検内容ファイル出力</th>
                  <td>点検内容のテキストファイル出力</td>
                  <td></td>
                </tr>
                <tr>
                  <th colSpan="3" className={styles.ttl}>精算処理</th>
                </tr>
                <tr>
                  <th className={styles.ttl2}>精算操作</th>
                  <td>在高登録</td>
                  <td></td>
                </tr>
                <tr>
                  <th rowSpan="24" className={styles.ttl2}>レシート印刷</th>
                  <td rowSpan="13">取引別集計</td>
                  <td>売上集計</td>
                </tr>
                <tr>
                  <td>値引集計</td>
                </tr>
                <tr>
                  <td>小計値引集計</td>
                </tr>
                <tr>
                  <td>返品集計</td>
                </tr>
                <tr>
                  <td>レジマイナス集計</td>
                </tr>
                <tr>
                  <td>税金集計</td>
                </tr>
                <tr>
                  <td>売掛集計</td>
                </tr>
                <tr>
                  <td>領収書集計</td>
                </tr>
                <tr>
                  <td>印紙集計</td>
                </tr>
                <tr>
                  <td>クーポン集計</td>
                </tr>
                <tr>
                  <td>領収書2枚以上</td>
                </tr>
                <tr>
                  <td>レシート2枚以上</td>
                </tr>
                <tr>
                  <td>現金過不足情報</td>
                </tr>
                <tr>
                  <td rowSpan="3">売上外集計</td>
                  <td>入金集計</td>
                </tr>
                <tr>
                  <td>出金集計</td>
                </tr>
                <tr>
                  <td>掛入金集計</td>
                </tr>
                <tr>
                  <td rowSpan="2">在高集計</td>
                  <td>釣銭準備金</td>
                </tr>
                <tr>
                  <td>現金集計</td>
                </tr>
                <tr>
                  <td>商品券集計</td>
                  <td></td>
                </tr>
                <tr>
                  <td>クレジットカード明細</td>
                  <td></td>
                </tr>
                <tr>
                  <td>部門別売上実績</td>
                  <td></td>
                </tr>
                <tr>
                  <td>オペレータ別売上実績</td>
                  <td></td>
                </tr>
                <tr>
                  <td>時間帯別売上実績</td>
                  <td></td>
                </tr>
                <tr>
                  <td>客層別売上実績</td>
                  <td></td>
                </tr>
                <tr>
                  <th className={styles.ttl2}>精算内容ファイル出力</th>
                  <td>精算内容のテキストファイル出力</td>
                  <td></td>
                </tr>
                <tr>
                  <th colSpan="3" className={styles.ttl}>各種設定処理</th>
                </tr>
                <tr>
                  <th className={styles.ttl2}>店舗情報設定</th>
                  <td>店舗共通情報設定</td>
                  <td></td>
                </tr>
                <tr>
                  <th className={styles.ttl2}>レジ情報設定</th>
                  <td>レジ固有設定</td>
                  <td></td>
                </tr>
                <tr>
                  <th className={styles.ttl2}>釣銭準備金変更</th>
                  <td>釣銭準備金変更</td>
                  <td></td>
                </tr>
                <tr>
                  <th rowSpan="9" className={styles.ttl2}>マスタ管理</th>
                  <td>商品メンテナンス</td>
                  <td></td>
                </tr>
                <tr>
                  <td>部門メンテナンス</td>
                  <td></td>
                </tr>
                <tr>
                  <td>顧客メンテナンス</td>
                  <td></td>
                </tr>
                <tr>
                  <td>客層メンテナンス</td>
                  <td></td>
                </tr>
                <tr>
                  <td>担当者メンテナンス</td>
                  <td></td>
                </tr>
                <tr>
                  <td>商品券メンテナンス</td>
                  <td>釣銭可否設定可能</td>
                </tr>
                <tr>
                  <td>特売メンテナンス</td>
                  <td></td>
                </tr>
                <tr>
                  <td>特売条件メンテナンス</td>
                  <td></td>
                </tr>
                <tr>
                  <td>税率メンテナンス</td>
                  <td></td>
                </tr>
                <tr>
                  <th colSpan="3" className={styles.ttl}>その他</th>
                </tr>
                <tr>
                  <th className={styles.ttl2}>売上データ削除</th>
                  <td>売上データの削除</td>
                  <td>期間指定</td>
                </tr>
                <tr>
                  <th className={styles.ttl2}>レジ明細パネル設定</th>
                  <td>レジ画面のカラー、フォント、明細行数等の設定</td>
                  <td></td>
                </tr>
                <tr>
                  <th rowSpan="12" className={styles.ttl2}>テキストデータ入出力</th>
                  <td>店舗マスタデータ入出力</td>
                  <td rowSpan="12">項目選択、並び順の定義、定義の保存、読込機能あり。<br />
                    「販売王」、「販売王2003」、DataNature(売上分析)用のレイアウト付属。</td>
                </tr>
                <tr>
                  <td>オペレータマスタデータ入出力</td>
                </tr>
                <tr>
                  <td>商品マスタデータ入出力</td>
                </tr>
                <tr>
                  <td>部門マスタデータ入出力</td>
                </tr>
                <tr>
                  <td>PLUマスタデータ入出力</td>
                </tr>
                <tr>
                  <td>顧客マスタデータ入出力</td>
                </tr>
                <tr>
                  <td>客層マスタデータ入出力</td>
                </tr>
                <tr>
                  <td>商品券マスタデータ入出力</td>
                </tr>
                <tr>
                  <td>特売マスタデータ入出力</td>
                </tr>
                <tr>
                  <td>特売条件マスタデータ入出力</td>
                </tr>
                <tr>
                  <td>税率マスタデータ入出力</td>
                </tr>
                <tr>
                  <td>売上データ出力</td>
                </tr>
                <tr>
                  <th className={styles.ttl2}>外部アプリ起動</th>
                  <td>外部アプリケーションの起動</td>
                  <td>起動アプリケーションを設定可能</td>
                </tr>
                <tr>
                  <th colSpan="3" className={styles.ttl}>メンテナンスツール</th>
                </tr>
                <tr>
                  <th className={styles.ttl2}>データ復旧</th>
                  <td>売上データ復旧（メンテナンス用）</td>
                  <td>取引ログを元に復旧 <br />
                      <ul>
                        <li>売上データ再構築</li>
                        <li>サマリ再構築</li>
                      </ul>
                      *店舗 &gt; レジNo &gt; 売上日毎
                    </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <th className={styles.ttl2}>ジャーナルチェック</th>
                  <td>ジャーナル間の整合性チェック</td>
                  <td>電子ジャーナルのメンテナンス用</td>
                </tr>
                </tfoot>
              </table>
            </div>

            <div id="qa" className={styles.tabContent}>
              <input type="checkbox" id="qa1" className={styles.checkbox} />
              <label htmlFor="qa1">Q1. 売上中に新規顧客を登録する方法は？</label>
              <div className={styles.checkboxShow}>
                <p>売上画面から顧客の簡易登録ができます。新顧客キーで開いたウインドウで新顧客の顧客コードを確定して下さい。この時、レシートプリンタからお客様登録票を出力されます(出さない設定も可能)。これをお客様に記入いただき、後で全ての情報を登録します。</p>
                <img src={ customters } alt="お客様登録票" className={styles.imgS} />
              </div>

              <input type="checkbox" id="qa2" className={styles.checkbox} />
              <label htmlFor="qa2">Q2. PLU登録されていなかった商品を売上中に登録するには？</label>
              <div className={styles.checkboxShow}>
                <p>入力された商品コードが登録されていなかった場合、新商品登録ウインドウが開きます。最低限の情報(部門、単価、税区分)を確定すると商品マスターに登録されます。新商品リストがレシートプリンタから出力されますので、後で商品名など詳細な情報を登録して下さい。</p>
                <img src={ newItems } alt="新商品登録レシート" className={styles.imgS} />
              </div>

              <input type="checkbox" id="qa3" className={styles.checkbox} />
              <label htmlFor="qa3">Q3. NON-PLUには対応していますか。</label>
              <div className={styles.checkboxShow}>
                <p>対応しております。</p>
              </div>

              <input type="checkbox" id="qa4" className={styles.checkbox} />
              <label htmlFor="qa4">Q4. 常に全画面で使いたいのですが。</label>
              <div className={styles.checkboxShow}>
                <p>Info-POSは解像度に合わせて全画面で表示されるよう自動的に調整されます。お使いのパソコン、ディスプレイの解像度にかかわらず、Info-POSは全画面でご使用いただけます。</p>
              </div>

              <input type="checkbox" id="qa5" className={styles.checkbox} />
              <label htmlFor="qa5">Q5. バンドルやミックスマッチは使えますか。</label>
              <div className={styles.checkboxShow}>
                <p>バンドル、ミックスマッチ(まとめ値引き)には現在対応しておりません。</p>
              </div>

              <input type="checkbox" id="qa6" className={styles.checkbox} />
              <label htmlFor="qa6">Q6. 期間特売やタイムサービスは使えますか。</label>
              <div className={styles.checkboxShow}>
                <p>商品単位で期間特売、タイムサービスに対応しております。</p>
              </div>

              <input type="checkbox" id="qa7" className={styles.checkbox} />
              <label htmlFor="qa7">Q7. レジの親子接続に対応していますか。</label>
              <div className={styles.checkboxShow}>
                <p>マスターの共有、売上の合算などには対応していませんが、日計集計のテキストファイル出力ができますので、複数のレジからこのファイルをEXCELなどに取り込み、集計していただくことは可能です。</p>
                <img src={ oyako } alt="Info-POSの親子レジ運用例" className={styles.imgL} />
              </div>

              <input type="checkbox" id="qa8" className={styles.checkbox} />
              <label htmlFor="qa8">Q8. ポイントカードに対応していますか。</label>
              <div className={styles.checkboxShow}>
                <p>ポイントカードには対応しておりません。</p>
              </div>

              <input type="checkbox" id="qa9" className={styles.checkbox} />
              <label htmlFor="qa9">Q9. サポート体制について教えて下さい。</label>
              <div className={styles.checkboxShow}>
                <p>製品のお問い合わせはE-Mail、FAX、電話で承っております。</p>
                <p>製品ご購入後3ヶ月間は無料で対応いたします。その後のお問い合わせには31,500円/年(税込)の年間サポート料をお支払いいただく必要があります。ハードウエアは1年間の保証期間がありますが、センドバック修理(弊社へお送りいただき、修理後返送)のみとなります。修理期間中の代替機は有償で貸し出しいたします。</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
